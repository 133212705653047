import request from "../utils/requst"


export const SearchFaeOrderUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/page',
        params,
    })
}

export const AddFaeOrderUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/add',
        data,
    })
}

export const EditaeOrderUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/edit',
        data,
    })
}

export const FaeSolveOrderUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/solve',
        data,
    })
}

export const FaeResetOrderUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/reset',
        data,
    })
}

export const transformFaeOperatorUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/transformFaeOperator',
        data,
    })
}

export const assignFaeOperatorUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/assignFaeOperator',
        data,
    })
}

export const getSalesListUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/getSalesList',
        params,
    })
}

export const getFaeOperatorListUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/getFaeOperatorList',
        params,
    })
}

export const getUserListByTypeUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/getUserListByType',
        params,
    })
}


export const FaeAdd = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/feedback/time/add',
        data,
    })
}

export const FaeFinish = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/complete',
        data,
    })
}

export const FaeWithdraw = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/withdraw',
        data,
    })
}

export const FaeExportExlUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/export',
        params,
        responseType: 'blob'
    })
}

export const FaeDelect = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/del',
        data,
    })
}

export const FaeRefreshOrder = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/development_work_order/reopen',
        data,
    })
}

export const FaeGetKf = params => {
    return request({
        method: 'GET',
        url: 'procedure/customer-work-order/get',
        params,
    })
}

export const transformUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/transform',
        data,
    })
}

export const getOneFaeUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/get',
        params,
    })
}


export const gettimeline = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/timeline/get',
        params,
    })
}

export const getyanfatimeline = params => {
    return request({
        method: 'GET',
        url: 'procedure/technical-support-work-order/development/get',
        params,
    })
}

export const noteComplaintFlag = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/noteComplaintFlag',
        data,
    })
}





export const getAssessListUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/customer-work-order-assess/page',
        params,
    })
}

export const getOneAssessUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/customer-work-order-assess/getAssessDetail',
        params,
    })
}


export const getwebsitpageUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/website-work-order/page',
        params,
    })
}
