import request from "../utils/requst"

export const getESpageUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/ec-serve-client-work-order/page',
        params,
    })
}
export const getOneESorderUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/ec-serve-client-work-order/get',
        params,
    })
}
export const EsOrderSolveOrderUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/ec-serve-client-work-order/solve',
        data,
    })
}

export const EsOrderEditUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/ec-serve-client-work-order/edit',
        data,
    })
}


export const getECSalesListUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/ec-serve-client-work-order/getECSalesList',
        params,
    })
}

export const transformFollowPersonUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/ec-serve-client-work-order/transformFollowPerson',
        data,
    })
}

export const ExportExlUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/ec-serve-client-work-order/export',
        params,
        responseType: 'blob'
    })
}

export const transformFaeOperatorUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/ec-serve-client-work-order/transform',
        data,
    })
}

export const DoReOpenUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/ec-serve-client-work-order/technical_support_work_order/reopen',
        data,
    })
}


